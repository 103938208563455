import TextFieldInputLabel from '@/components/form/inputLabel';
import currencyFormat from '@/helpers/currencyFormat';
import { Payment } from '@/types/schema';
import { Box, ListItemText, MenuItem, Select } from '@mui/material';
import { startCase } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ClientPaymentSelection( {
	clientPayments,
	selectedClientPayment,
	setSelectedClientPayment,
	totalRemaining,
}: {
	clientPayments?: Payment[],
	selectedClientPayment?: Payment | null,
	setSelectedClientPayment?: ( payment: Payment | null ) => void,
	totalRemaining?: number
} ) {
	const { t } = useTranslation();
	
	return (
		<Box sx={{ my: 1 }}>
			<TextFieldInputLabel label='Select Client Payment'/>
			<Select
				fullWidth
				size='small'
				placeholder={t( 'common:payment' )}
				value={selectedClientPayment?.id || ''}>
				{clientPayments?.map( ( payment, index ) => (
					<MenuItem
						key={index}
						selected
						disabled={totalRemaining ? totalRemaining > payment.amount : false}
						value={payment.id}
						onClick={() => setSelectedClientPayment?.( payment )}>
						<ListItemText primary={`${currencyFormat( payment?.amount )} (${startCase( payment.type )})`}/>
					</MenuItem>
				) )}
			</Select>
		</Box>
	);
}
